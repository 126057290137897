<template>
  <div class="columns network">
    <div class="column col-7 col-md-12">
      <h1>{{ $t("network.headline") }}</h1>
      <p>{{ $t("network.text") }}</p>
    </div>
    <div class="column col-5 col-md-12 network__image-container">
      <img :src="networkSVG" class="network__image" />
    </div>
    <div class="column col-12">
      <div class="columns margin--xl">
        <div
          class="column col-4 col-sm-12"
          v-for="(part, index) in $t('network.country_parts')"
          :key="index"
          style="margin-bottom: 1.5rem;"
        >
          <h4>{{ part.name }}</h4>
          <ul class="network__list">
            <li
              class="network__list-item"
              v-for="(country, index) in part.countries"
              :key="index"
            >
              {{ country }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="column column--full-width-bg">
      <div
        class="column col-6 col-sm-12 col-md-10 col-lg-9 col-xl-8 txt--center"
      >
        <h4>{{ $t("network.link_headline") }}</h4>
        <router-link class="button button--arrow-left" to="/contact">{{
          $t("navigation.letstalk")
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Network",
  data() {
    return {
      networkSVG: require("../../assets/images/network.png"),
    };
  },
};
</script>
<style lang="scss" scoped>
.column--full-width-bg {
  @include column--full-width-bg($color-primary-50);
}
.network {
  .column:not(.network__image-container) {
    @media (max-width: $size-sm + 1) {
      position: relative;
      z-index: 1;
    }
  }
  &__image {
    @media (max-width: $size-sm + 1) {
      display: none;
      margin-bottom: 4rem;
    }

    top: 6vw;
    position: relative;
  }

  &__list {
    list-style-type: none;
    margin: 0;
  }

  &__list-item {
    padding-left: 1.7em;
    position: relative;

    &::before {
      background-color: $color-primary-100;
      content: "";
      display: block;
      height: 0.2em;
      left: 0;
      position: absolute;
      top: 0.7em;
      width: 1em;
    }
  }
}
</style>
